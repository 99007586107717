import {
  useChangeBuildingUserRoleMutation,
  useGetBuildingUsersQuery,
  useRemoveBuildingUserMutation,
} from '../../../store/buildings'
import {
  Avatar, Button,
  Card,
  CardHeader,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import Box from '@mui/material/Box'
import React, { useEffect, useMemo, useState } from 'react'
import { AddBuildingUserDialog } from './AddBuildingUserDialog'
import { toast } from 'react-toastify'
import { useAuthorization } from '../../../auth'
import { useLocation, useOutletContext } from 'react-router-dom'
import { BaseContainer } from '../../common/Container'
import { IOutletBuilding } from '../../../types/outlet-context'
import { RightPanel } from '../../common'
import { UserView } from './UserView'
import StarIcon from '@mui/icons-material/Star';

export const BuildingUsers = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  const authManager = useAuthorization()
  const [remove] = useRemoveBuildingUserMutation()
  const { data: users, refetch } = useGetBuildingUsersQuery(building.id)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()
  const location = useLocation()

  const currentItem = useMemo(() => {
    return users && users.find((item) => item.id === currentId)
  }, [users, currentId])


  const isManage = authManager.vote('BuildingUser', 'manage')

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  useEffect(() => {
    if (location.hash){
      viewItem(location.hash.substring(1))
    }
  }, [location])


  const handleRemove = (userId: string, e: React.MouseEvent) => {
    e.stopPropagation()
    remove({
      buildingId: building.id,
      userId,
    })
      .unwrap()
      .then(() => {
        toast.success('Пользователь отключен от объекта')
      })
  }

  return (
    <>
      <BaseContainer>
        <TableContainer component={Card}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: indigo[500] }} aria-label="recipe">
                <ManageAccountsIcon />
              </Avatar>
            }
            action={<AddBuildingUserDialog building={building} />}
            title="Пользователи"
            subheader="администраторы/наблюдатели"
          />
          <Table width={'100%'}>
            <TableBody>
              {users &&
                users.map((user) => {
                  return (
                    <TableRow key={user.id} hover sx={{ cursor: 'pointer' }} onClick={() => viewItem(user.id)}>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            {user.profile ? user.profile.name.full_name : user.id}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{textAlign: 'right'}}>
                        {user.role === 'admin' && <Box title={'Администратор'}><StarIcon color={'success'} /></Box>}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </BaseContainer>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && currentItem && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={(e) => handleRemove(currentItem.id, e)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && <UserView user={currentItem} building={building} onChange={refetch} isManage={isManage} />}
      </RightPanel>
    </>
  )
}
